import { useState, useEffect } from 'react';
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { Button, Spinner, Card, Form} from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from  "../assets/autodata-logo.png"

export default function  ResetPassword() {
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [isLoading, setIsloading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const handleResetPassword = async () => {
        setIsloading(!isLoading)

        if(newPassword !== confirmNewPassword){
            setIsloading(isLoading)
            return alert("Password do not matched")
        }

        const reqUrl = "https://us-central1-autodata-742cd.cloudfunctions.net/autodata/get-customer";
      const reqBody = {
        phone_number: phoneNumber,
      }
        return await axios.post(reqUrl,reqBody).then(async (result) => {
            const reqUrl = "https://us-central1-autodata-742cd.cloudfunctions.net/autodata/reset-user-password";
            const reqBody = {
              phone_number: phoneNumber,
              password: newPassword
            }
            return axios.post(reqUrl,reqBody).then((response) => {
                setIsloading(isLoading)
                navigate("/customer-account/password-success")
            }).catch((error) => {
                if(error){
                  setIsloading(isLoading);
                alert(error.response.data.message)
                
                }
              })
        }).catch((error) => {
          if(error){
            setIsloading(isLoading);
          alert(error.response.data.message)
          
          }
        })
    }

    useEffect(() => {
      const customer = searchParams.get('customer')
        setPhoneNumber(customer)
      return () => {
        
      }
    }, [searchParams])
    

  return (
    <div style={{display: "flex", padding: "50px", alignItems: "center",justifyContent: "center"}}> 
         <Card bg='light' style={{ width: '25rem', height: '300px', alignItems: 'center', justifyContent: 'center' }}
          className="mb-2">
            
         <Card.Img variant="top"
         style={{width: '50px', }}
          src={logo} />
         <Card.Title>Reset Password</Card.Title>
      <Card.Body style={{ width: '20rem', alignItems: 'center', justifyContent: 'center' }}>
        <Form.Control type="password" onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" />
      <br />
      <Form.Control type="password" onChange={(e) => setConfirmNewPassword(e.target.value)} placeholder="Confirm Password" />
      <br />
      <Button onClick={handleResetPassword} style={{backgroundColor: "#00bcc0", textAlign: 'center',alignItems: 'center', justifyContent: 'center', width: '100%'}}>{!isLoading ? "Reset" : <Spinner animation="border" /> }</Button>
      </Card.Body>
      
    </Card>
    </div>
  )
}
