import React, { useState, useEffect } from "react";
import axios from "axios";
import { RechargeCardSalesData } from "../data/sample-data";
import AppNavBar from "./navbars/AppNavBar";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Spinner, Table, Card, Form, Alert } from "react-bootstrap";
import { withSwal } from "react-sweetalert2";

import image1 from "../assets/buy-data.png";
import image2 from "../assets/airtime.png";
import image3 from "../assets/bill.png";
import image4 from "../assets/bulk-sms.png";
import image5 from "../assets/send.png";
import image6 from "../assets/builk-data.png";
import image7 from "../assets/auto-buy.png";
import banner from "../assets/banner.png";
import Playstore from "../assets/Playstore.png";
import AppStore from "../assets/Appstore.png";
import MTNLogo from "../assets/mtn.png";
import AirtelLogo from "../assets/airtel.png";
import GLOLogo from "../assets/glo.jpg";
import NINEMOBILELogo from "../assets/9mobile.jpg";
import contact0 from "../assets/contact0.png";
import contact1 from "../assets/contact1.png";
import TopBtn from "./Buttons/TopBtn";

function convertToReadableCurrency(num) {
  // num = num.toFixed(2)
  num = Number(num).toLocaleString();
  return num;
}
function convertToReadable(num) {
  let result;
  let MB, GB, TB;
  if (num <= 999) {
    result = `${num + " MB"}`;
  }
  if (num >= 1000) {
    result = `${num / 1000 + " GB"}`;
  }
  if (num >= 1000000) {
    result = `${num / 1000 / 1000 + " TB"}`;
  }
  return result;
}

export default function Home() {
  const [totalSales, setTotalSales] = useState(0);
  const [names, setNames] = useState([]);
  const [planList, setPlanList] = useState([]);

  let people = [];
  let result = 0;

  const handleGetDataPlans = async () => {
    await axios
      .post(
        "https://us-central1-autodata-742cd.cloudfunctions.net/autodata/get-our-data-plans",
        { provider_id: 1 }
      )
      .then((res) => {
        const responseData = res.data.responseBody.data_plans;

        setPlanList(responseData);
      });
  };

  useEffect(() => {
    // handleGetData();
    handleGetDataPlans();

    return () => {};
  }, [names]);

  return (
    <body
      style={{
        width: "100%",
        backgroundColor: "#fff",
        scrollBehavior: "smooth",
      }}
    >
      <Container
        fluid
        style={{ backgroundColor: "#00bcc0", padding: "5px", color: "#fff" }}
      >
        <Container>
          <Row
            style={{
              marginTop: "20px",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Col lg={true}>
              <span style={{ fontSize: "2em", fontWeight: "bolder" }}>
                Automation & Bulk Services
              </span>
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "1.5em",
                }}
              >
                We have added value in the mobile data sales market by building
                a bulk share of data for friends and family or staff groups, as
                many as you wish. We also automate it so that you can set up a
                daily, weekly, or monthly auto-renewal service.
              </p>
              <button
                style={{
                  width: '100%',
                  padding: "10px",
                  margin: "10px",
                  boxShadow: `2px 2px 2px `,
                  borderRadius: "24px",
                  border: "none",
                  color: "black",
                  fontSize: "15px",
                  fontStyle: "italic",
                  fontWeight: "500",
                }}
              >
                Get Started
              </button>
            </Col>
            <Col
              lg={true}
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <img src={banner} alt="" style={{ width: "100%" }} />
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  href="#"
                  onClick={() => {
                    alert("Will be available soon");
                  }}
                >
                  <img src={Playstore} alt="" style={{ width: "25%" }} />
                </a>{" "}
                <a
                  href="#"
                  onClick={() => {
                    alert("Will be available soon");
                  }}
                >
                  <img src={AppStore} alt="" style={{ width: "25%" }} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container fluid style={{ textAlign: "center", padding: "25px" }}>
          <Row>
            <Col lg={true}>
            <div
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  color: "black",
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1 className="section-header">What We Do</h1>
              </div>
            </Col>
          </Row>
        </Container>
        <section id="services">
          <Container>
            <Row>
              <Col lg={true}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    style={{
                       width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: '10px 10px 20px 10px',
                      border: `1px solid #BFBFBF`,
                      backgroundColor: 'white',
                      boxShadow: `5px 5px 5px #aaaaaa`,
                    }}
                    className="mb-3"
                  >
                    <Card.Title>
                      <div style={{ textAlign: "center" }}>
                        <Card.Img
                          variant="top"
                          style={{
                            width: "50px",
                            height: "50px",
                            marginTop: "20px",
                          }}
                          src={image1}
                        />
                      </div>
                      <h3
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          fontWeight: "bolder",
                        }}
                      >
                        Data
                      </h3>
                    </Card.Title>
                    <Card.Body
                      style={{
                        marginTop: "-25px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ textAlign: "center", fontSize: "1.5em" }}>
                      We help you purchase mobile data single or in bulk, and you can set up auto-renew.
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </Col>

              <Col lg={true}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    style={{
                       width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: '10px 10px 20px 10px',
                      border: `1px solid #BFBFBF`,
                      backgroundColor: 'white',
                      boxShadow: `5px 5px 5px #aaaaaa`,
                    }}
                    className="mb-3"
                  >
                    <Card.Title>
                      <div style={{ textAlign: "center" }}>
                        <Card.Img
                          variant="top"
                          style={{
                            width: "50px",
                            height: "50px",
                            marginTop: "20px",
                          }}
                          src={image2}
                        />
                      </div>
                      <h3
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          fontWeight: "bolder",
                        }}
                      >
                        Airtime
                      </h3>
                    </Card.Title>
                    <Card.Body
                      style={{
                        marginTop: "-25px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ textAlign: "center", fontSize: "1.5em" }}>
                      You can purchase airtime single or in bulk, and you can also set up auto-renew.
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              
              <Col lg={true}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    style={{
                       width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: '10px 10px 20px 10px',
                      border: `1px solid #BFBFBF`,
                      backgroundColor: 'white',
                      boxShadow: `5px 5px 5px #aaaaaa`,
                    }}
                    className="mb-3"
                  >
                    <Card.Title>
                      <div style={{ textAlign: "center" }}>
                        <Card.Img
                          variant="top"
                          style={{
                            width: "50px",
                            height: "50px",
                            marginTop: "20px",
                          }}
                          src={image4}
                        />
                      </div>
                      <h3
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          fontWeight: "bolder",
                        }}
                      >
                        SMS
                      </h3>
                    </Card.Title>
                    <Card.Body
                      style={{
                        marginTop: "-25px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ textAlign: "center", fontSize: "1.5em" }}>
                      Sending bulk SMS through our app can help you achieve the fastest delivery. 
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
             
            </Row>
          </Container>
        </section>
      </Container>
      <section id="solutions">
        <Container style={{ textAlign: "center", padding: "25px" }}>
          <Row>
            <Col lg={true}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  color: "black",
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1 className="section-header">Solutions We Provide</h1>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={true}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Card
                  style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: '10px 10px 20px 10px',
                    border: `1px solid #BFBFBF`,
                    backgroundColor: 'white',
                    boxShadow: `5px 5px 5px #aaaaaa`,
                  }}
                  className="mb-3"
                >
                  <Card.Title>
                    <div style={{ textAlign: "center" }}>
                      <Card.Img
                        variant="top"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginTop: "20px",
                        }}
                        src={image6}
                      />
                    </div>
                    <h3
                      style={{
                        textAlign: "center",
                        marginTop: "15px",
                        fontWeight: "bolder",
                      }}
                    >
                      Bulk
                    </h3>
                  </Card.Title>
                  <Card.Body
                    style={{
                      marginTop: "-25px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ textAlign: "center", fontSize: "1.5em" }}>
                      You can easily send data, airtime, and money to a large
                      number of beneficiaries at once with this solution.
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </Col>

            <Col lg={true}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Card
                  style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: '10px 10px 20px 10px',
                    border: `1px solid #BFBFBF`,
                    backgroundColor: 'white',
                    boxShadow: `5px 5px 5px #aaaaaa`,
                  }}
                 className="mb-3">
                  <Card.Title>
                    <div style={{ textAlign: "center" }}>
                      <Card.Img
                        variant="top"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginTop: "20px",
                        }}
                        src={image7}
                      />
                    </div>
                    <h3
                      style={{
                        textAlign: "center",
                        marginTop: "15px",
                        fontWeight: "bolder",
                      }}
                    >
                      Auto-Renew
                    </h3>
                  </Card.Title>
                  <Card.Body
                    style={{
                      marginTop: "-25px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ textAlign: "center", fontSize: "1.5em" }}>
                    You can always set up a daily, weekly, or monthly auto-renew service on any transactions with us.
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="pricing">
        <Container style={{ textAlign: "center", padding: "25px" }}>
          <Row>
            <Col lg={true}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  color: "black",
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1 className="section-header">Live Data Pricing</h1>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Container
            style={{
              padding: "24px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={MTNLogo}
              alt=""
              style={{ width: "80px", borderRadius: "40px" }}
            />
          </Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {planList
              .filter((plan) => {
                return plan.network === "MTN";
              })
              .map((item) => (
                <Card style={{ margin: "10px" }}>
                  <Card.Body>
                    <center>
                      <Card.Title style={{ color: "#00bcc0" }}>
                        {item.type === "SME"
                          ? "Plan A"
                          : item.type === "SME2"
                          ? "Plan B"
                          : item.type === "CORPORATE GIFTING"
                          ? "Plan C"
                          : item.type === "GIFTING"
                          ? "Plan E"
                          : item.type === "DATA COUPONS"
                          ? "Plan F"
                          : item.type === "DATA AWOOF"
                          ? "Plan G"
                          : "Plan H"}
                      </Card.Title>
                    </center>
                    <center>
                      <b>MTN</b>
                      <br></br>
                      <b>{convertToReadable(item.quantity)}</b> <br></br>
                      <b>
                        <span style={{ textDecorationLine: "line-through" }}>
                          N
                        </span>
                        {convertToReadableCurrency(item.price)}
                      </b>
                    </center>
                  </Card.Body>
                </Card>
              ))}
          </div>
        </Container>
        <Container>
          <Container
            style={{
              padding: "24px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={AirtelLogo}
              alt=""
              style={{ width: "80px", borderRadius: "40px" }}
            />
          </Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {planList
              .filter((plan) => {
                return plan.network === "AIRTEL";
              })
              .map((item) => (
                <Card style={{ margin: "10px" }}>
                  <Card.Body>
                    <center>
                      <Card.Title style={{ color: "#00bcc0" }}>
                        {item.type === "SME"
                          ? "Plan A"
                          : item.type === "SME2"
                          ? "Plan B"
                          : item.type === "CORPORATE GIFTING"
                          ? "Plan C"
                          : item.type === "GIFTING"
                          ? "Plan E"
                          : item.type === "DATA COUPONS"
                          ? "Plan F"
                          : item.type === "DATA AWOOF"
                          ? "Plan G"
                          : "Plan H"}
                      </Card.Title>
                    </center>
                    <center>
                      <b>AIRTEL</b>
                      <br></br>
                      <b>{convertToReadable(item.quantity)}</b> <br></br>
                      <b>
                        <span style={{ textDecorationLine: "line-through" }}>
                          N
                        </span>
                        {convertToReadableCurrency(item.price)}
                      </b>
                    </center>
                  </Card.Body>
                </Card>
              ))}
          </div>
        </Container>
        <Container>
          <Container
            style={{
              padding: "24px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={GLOLogo}
              alt=""
              style={{ width: "80px", borderRadius: "40px" }}
            />
          </Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {planList
              .filter((plan) => {
                return plan.network === "GLO";
              })
              .map((item) => (
                <Card style={{ margin: "10px" }}>
                  <Card.Body>
                    <center>
                      <Card.Title style={{ color: "#00bcc0" }}>
                        {item.type === "SME"
                          ? "Plan A"
                          : item.type === "SME2"
                          ? "Plan B"
                          : item.type === "CORPORATE GIFTING"
                          ? "Plan C"
                          : item.type === "GIFTING"
                          ? "Plan E"
                          : item.type === "DATA COUPONS"
                          ? "Plan F"
                          : item.type === "DATA AWOOF"
                          ? "Plan G"
                          : "Plan H"}
                      </Card.Title>
                    </center>
                    <center>
                      <b>GLO</b>
                      <br></br>
                      <b>{convertToReadable(item.quantity)}</b> <br></br>
                      <b>
                        <span style={{ textDecorationLine: "line-through" }}>
                          N
                        </span>
                        {convertToReadableCurrency(item.price)}
                      </b>
                    </center>
                  </Card.Body>
                </Card>
              ))}
          </div>
        </Container>
        <Container>
          <Container
            style={{
              padding: "24px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={NINEMOBILELogo}
              alt=""
              style={{ width: "80px", borderRadius: "40px" }}
            />
          </Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {planList
              .filter((plan) => {
                return plan.network === "9MOBILE";
              })
              .map((item) => (
                <Card style={{ margin: "10px" }}>
                  <Card.Body>
                    <center>
                      <Card.Title style={{ color: "#00bcc0" }}>
                        {item.type === "SME"
                          ? "Plan A"
                          : item.type === "SME2"
                          ? "Plan B"
                          : item.type === "CORPORATE GIFTING"
                          ? "Plan C"
                          : item.type === "GIFTING"
                          ? "Plan E"
                          : item.type === "DATA COUPONS"
                          ? "Plan F"
                          : item.type === "DATA AWOOF"
                          ? "Plan G"
                          : "Plan H"}
                      </Card.Title>
                    </center>
                    <center>
                      <b>9MOBILE</b>
                      <br></br>
                      <b>{convertToReadable(item.quantity)}</b> <br></br>
                      <b>
                        <span style={{ textDecorationLine: "line-through" }}>
                          N
                        </span>
                        {convertToReadableCurrency(item.price)}
                      </b>
                    </center>
                  </Card.Body>
                </Card>
              ))}
          </div>
        </Container>
      </section>
      <section id="about">
        <Container fluid>
          <Container style={{ textAlign: "center", padding: "25px" }}>
            <Row>
              <Col lg={true}>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    color: "black",
                    padding: 10,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h1 className="section-header">Who We Are</h1>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={true}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Card
                     style={{  width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: '10px 10px 20px 10px',
                      border: `1px solid #BFBFBF`,
                      backgroundColor: 'white',
                      boxShadow: `5px 5px 5px #aaaaaa`,
                    
                    }}
                    className="mb-3"
                  >
                    <Card.Title>
                      <div style={{ textAlign: "center" }}></div>
                      <h3
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          fontWeight: "bolder",
                        }}
                      >
                        AutoData
                      </h3>
                    </Card.Title>
                    <Card.Body
                      style={{
                        marginTop: "-25px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ textAlign: "center", fontSize: "1.5em" }}>
                        Is a subsidiary of{" "}
                        <a
                          href="https://www.autopay.africa/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          AutoPay
                        </a>{" "}
                        the trending multinational payment platform in Africa,
                        that fills many gaps in the current payment industry.  {" "}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>
      <section id="contact">
        <Container fluid>
          <Container style={{ textAlign: "center", padding: "25px" }}>
            <Row>
              <Col lg={true}>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    color: "black",
                    padding: 10,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h1 className="section-header">Get In Touch</h1>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={true}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Card
                     style={{ width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: '10px 10px 20px 10px',
                      border: `1px solid #BFBFBF`,
                      backgroundColor: 'white',
                      boxShadow: `5px 5px 5px #aaaaaa`,  }}
                    className="mb-3"
                  >
                    <Card.Title>
                      <div style={{ textAlign: "center" }}>
                        <Card.Img
                          variant="top"
                          style={{
                            width: "50px",
                            height: "50px",
                            marginTop: "20px",
                          }}
                          src={contact0}
                        />
                      </div>
                      <h4 style={{ textAlign: "center", marginTop: "10px" }}>
                        Visit Us @
                      </h4>
                    </Card.Title>
                    <Card.Body
                      style={{
                        width: "100%",
                        marginTop: "-25px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h4 style={{ textAlign: "center" }}>
                        No. 149 Nagoda Street, Mandawari Kano, Nigeria.
                      </h4>
                    </Card.Body>
                  </Card>
                </div>
              </Col>

              <Col lg={true}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: '10px 10px 20px 10px',
                      border: `1px solid #BFBFBF`,
                      backgroundColor: 'white',
                      boxShadow: `5px 5px 5px #aaaaaa`,
                    }}
                    className="mb-3"
                  >
                    <Card.Title>
                      <div style={{ textAlign: "center" }}>
                        <Card.Img
                          variant="top"
                          style={{
                            width: "50px",
                            height: "50px",
                            marginTop: "20px",
                          }}
                          src={contact1}
                        />
                      </div>
                      <h4 style={{ textAlign: "center", marginTop: "15px" }}>
                        Talk To Us
                      </h4>
                    </Card.Title>
                    <Card.Body
                      style={{
                        marginTop: "-25px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h4 style={{ textAlign: "center" }}>08081687657</h4>
                     <strong>Phone Call / WhatsApp</strong>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>

      <TopBtn />

      <footer>
        <div
          style={{
            width: "100%",
            height: "50%",
            textAlign: "center",
            color: "#fff",
            backgroundColor: "#00bcc0",
          }}
        >
          <div className="footer-info">
            <p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  href="/privacy-policy"
                  style={{
                    color: "#fff",
                    fontWeight: "500",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </a>
                <a
                  href="/refund-policy"
                  style={{
                    marginLeft: "10px",
                    color: "#fff",
                    fontWeight: "500",
                    textDecoration: "none",
                  }}
                >
                  Refund Policy
                </a>
              </div>
              &copy; <span id="displayYear"></span> Copyright 2025{" "}
              <a
                href="https://www.autopay.africa/"
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  textDecoration: "none",
                }}
              >
                AutoPay
              </a>
              . All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </body>
  );
}
