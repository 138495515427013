import React, { useState, useEffect } from "react";
import {
  NavDropdown,
  Nav,
} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import NavbarBrand from "react-bootstrap/esm/NavbarBrand";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import Container from "react-bootstrap/esm/Container";
import "../../css/navbar.css";

const AppNavBar = () => {
  return (
    <Navbar
    collapseOnSelect
    className="nav-bar-header"
    expand="lg"
    sticky="top"
    
  >
    <Container>
      <Navbar.Brand>
       
    <a href="/" style={{textDecoration: 'none'}}>
    <span className="company-name">
          <span className="c-name1">AUTODATA</span>
          </span>
    </a>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <div className="nav-menu">
            <Nav.Link href="/" className="nav-link">
              <span style={{color: '#fff'}}>Home</span>
            </Nav.Link>

            <Nav.Link href="#services" className="nav-link">
            <span style={{color: '#fff'}}>Services</span>
            </Nav.Link>
            <Nav.Link href="#solutions" className="nav-link">
            <span style={{color: '#fff'}}>Solutions</span>
            </Nav.Link>
            <Nav.Link href="#pricing" className="nav-link">
            <span style={{color: '#fff'}}>Pricing</span>
            </Nav.Link>
            
            <Nav.Link href="#about" className="nav-link">
            <span style={{color: '#fff'}}>About</span>
            </Nav.Link>
            <Nav.Link href="#contact" className="nav-link">
            <span style={{color: '#fff'}}>Contact</span>
            </Nav.Link>
          </div>
          <div className="">
            <Nav.Link href="/login">
            <span style={{color: '#fff'}}>Login</span>
            </Nav.Link>
          </div>
          <div className="">
            <Nav.Link href="/login">
            <span style={{color: '#fff'}}>Register</span>
            </Nav.Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>

  );
};

export default AppNavBar;
