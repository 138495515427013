import React, { useState, useEffect } from "react";

const TopBtn = () => {
  const [componentState, setComponentState] = useState(false);

  if (!componentState) {
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = () => {
      scrollFunction();
    };
    
  }
  
  // get the button
  const myButton = document.getElementById("topBtn");

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 40 ||
      document.documentElement.scrollTop > 40
    ) {
      myButton.style.display = "block";
    } else {
      myButton.style.display = "none";
    }
  };
  // When the user clicks on the button, scroll to the top of the document
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    setComponentState(!componentState);
  },[componentState]);

  return (
    <button onClick={topFunction}>
      <i
        className="fa fa-arrow-up"
        id="topBtn"
        title="Go to top"
      ></i>
    </button>
  );
};

export default TopBtn;
