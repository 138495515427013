import logo from './logo.svg';
import './css/App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ResetPassword from './components/ResetPassword';
import AccountActivation from './components/AccountActivation';
import Home from './components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import PasswordSuccessPage from './components/PasswordSuccessPage';
import ActivationSuccessPage from './components/ActivationSuccessPage';
import AccountDeletion from './components/AccountDeletion';
import PrivacyPolicy from './components/PrivacyPolicy';
import AppNavBar from './components/navbars/AppNavBar';

function App() {
  return (
    <Router>
      <AppNavBar/>
      <Routes>

      <Route path='/' element={<Home/>}></Route>
        <Route path='/customer-account/password-reset' element={<ResetPassword/>}></Route>
        <Route path='/customer-account/account-activation' element={<AccountActivation/>}></Route>
        <Route path='/customer-account/password-success' element={<PasswordSuccessPage/>}></Route>
        <Route path='/customer-account/activation-success' element={<ActivationSuccessPage/>}></Route>
        <Route path='/customer-account-deletion-success' element={<AccountDeletion/>}></Route>
        <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;

