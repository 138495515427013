import { useState, useEffect, useCallback } from 'react';
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { Button, Spinner, Card, Form, Alert, } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from  "../assets/autodata-logo.png"

export default function AccountActivation() {
  const [isLoading, setIsloading] = useState(false);
  const [isSuccessfull, setIsSuccessfull] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const getCustomer = useCallback(
    () => {
      const customer = searchParams.get('customer');
      setPhoneNumber(customer);
      
    },
    [],
  )
  
  const handleAccountActivation = async () => {
      setIsloading(!isLoading)
      const reqUrl = "https://2cgjpk7lo2.execute-api.eu-north-1.amazonaws.com/prod/get-customer";
    const reqBody = {
      phone_number: phoneNumber,
    }
      return await axios.post(reqUrl,reqBody).then(async (result) => {
          const reqUrl = "https://2cgjpk7lo2.execute-api.eu-north-1.amazonaws.com/prod/activate-user-account";
          const reqBody = {
            phone_number: phoneNumber,
            account_status: "activated"
          }
          return axios.post(reqUrl,reqBody).then((response) => {
            
            setIsloading(isLoading);
            return navigate("/customer-account/activation-success")
          }).catch((error) => {
              if(error){
                setIsloading(isLoading);
             return alert(error.response.data.message)
              
              }
            })
      }).catch((error) => {
        if(error){
          setIsloading(isLoading);
       return alert(error.response.data.message)
        
        }
      })
  }


  useEffect(() => {
    getCustomer();
    return () => {
      
    }
  }, [])
  

  return (
    <div style={{display: "flex", padding: "50px", alignItems: "center",justifyContent: "center"}}> 
         <Card bg='light' style={{ width: '25rem', height: '200px', alignItems: 'center', justifyContent: 'center' }}
          className="mb-2">
            
         { <>
         <Card.Title>
         <Card.Img variant="top"
         style={{width: '50px', marginTop: '20px'}}
          src={logo} />
         </Card.Title>
      <Card.Body style={{ width: '25rem', alignItems: 'center', justifyContent: 'center' }}>
        <h3 style={{textAlign: "center"}}>Click button below to confirm activation.</h3>
        <Button onClick={handleAccountActivation} style={{backgroundColor: "#00003f", textAlign: 'center',alignItems: 'center', justifyContent: 'center', width: '100%'}}>{!isLoading ? "Confirm Activation" : <Spinner animation="border" /> }</Button>
      
      </Card.Body>
      </>}
    </Card>
    </div>

  )
}
